@import "../../theme/fonts.scss";
@import "../../theme/variables.scss";

.recent-subscription {
  font-weight: 600;
  font-family: "Open-Sans";
  font-size: 18px;
  color: $secondary-color;
}
.payment-edit-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.subscription-table-data {
  padding: 10px !important;
  text-align: start !important;
}

.payment-chart-component-container{
  width: 50%;
  box-sizing: border-box;
  padding: 5px;
}

.subscription-model{
  margin-right: 16px;
}
@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.width75 {
    .modal-content {
      width: 75%;
    }
  }
.delete-popup-container {
    // padding: 5px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .popup-close-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .delete-users {
    font-family: "Open-Sans";
    font-weight: 600;
    color: $secondary-color;
    font-size: 18px;
    line-height: 21.79px;
  }
  .confirm-delete-container {
    border-bottom: 1px solid $border-color;
  }
  .confirm-delete {
    font-family: "Open-Sans";
    font-weight: 400;
    font-size: 14px;
    color: $secondary-color;
    margin: 0 20px 20px 20px;
  }
  .delete-popup-btn-container {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
  }
  .cancel-btn-container {
    width: 104px;
    margin-right: 10px;
    height: 40px;
  }
  .delete-btn-container {
    width: 86px;
    height: 40px;
  }
 
  .delete-popup-modal {
    position: absolute;
    top: 100%;
    right: 0;
    width: 101px;
    height: 116px;
  }

  .disabled-apple{ 
    background-color: rgb(255 223 223) !important;
  }

  .filtered-apple-user{
    font-family: "Open-Sans";
    color: red;
    font-size: 13px;
    margin-top: 1rem;
  }
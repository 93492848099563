@import "../../theme/variables.scss";

.side-bar-container {
  height: 100%;
  width: 15%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $secondary-color;
  box-shadow: -31px -24px 33px 12px rgb(107, 107, 107);
  overflow-y: auto; 
  overflow-x: hidden;
  transition: 0.5s;
}

.sidebar-image-container {
  border-bottom: 2px solid $border;
  height: 62px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sidebar-icon {
  width: 20px;
  height: 20px;
}
.active-label {
  letter-spacing: 0.02em;
  color: #ffffff !important;
  transition: display 0.01s;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  font-family: "Open-Sans";
}

.in-active-label {
  transition: display 0.01s;
  letter-spacing: 0.02em;
  color: #8a92a6;
  margin-left: 12px;
  font-weight: 500;
  font-size: 14px;
  font-family: "Open-Sans";
  margin-top: 5px;
}

.active-sidebar-item {
  background-color: rgba(255, 255, 255, 0.15) !important;
  border-radius: 4px !important;
  padding: 10px 0;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.in-active-sidebar-item {
  padding: 10px 0;
  margin-left: 10px;
  margin-right: 10px;
}

.active-icon {
  width: 20px;
  height: 20px;
}

.side-bar-list {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.logout-side-bar-list {
  margin-left: 30px;
  display: flex;
  align-items: center;
}
.item-side-bar {
  margin-top: 20px;
  height: 80%;
}
.side-bar-logo {
  width: 62.67px;
  height: 45.33px;
  margin-left: 20px;
  margin-top: 5px;
  cursor: pointer;
}
.bottom-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

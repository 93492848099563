@import "../../theme/fonts.scss";
@import "../../theme/variables.scss";

.terms-main-container {
  padding: 10px 25px;
}
.terms-title {
  font-weight: 600;
  font-size: 24px;
  font-family: "Open-Sans";
  color: $secondary-color;
}
.terms-container {
  border: 1px solid $primary-color;
  border-radius: 4px;
  padding: 12px 16px 12px 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open-Sans";
  color: $primary-color;
}
.terms-icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  cursor: pointer;
}

@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.login-btn {
  width: 327px;
  height: 40px;
  padding: 8px;
  background-color: $green;
  color: white;
  border: none;
  border-radius: 4px;

  cursor: pointer;

  .btn-label {
    font-family: "Open-Sans";
    font-weight: 600;
    font-size: 14px;
    color: white;
  }
  &:hover {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
    transition: 0.5s ease-in-out;
    .btn-label {
      font-family: "Open-Sans";
      font-weight: 600;
      font-size: 14px;
      color: $primary-color;
    }
  }
}

.disable-btn {
  cursor: not-allowed;
  width: 327px;
  height: 40px;
  padding: 8px;
  background-color: $green;
  color: white;
  border: none;
  border-radius: 4px;
  .btn-label {
    font-family: "Open-Sans";
    font-weight: 600;
    font-size: 14px;
    color: white;
  }
}

.main-bg-btn {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: $green;
  .btn-label {
    font-family: "Open-Sans";
    font-weight: 600;
    font-size: 14px;
    color: white;
  }
}
.cancel-btn {
  width: 100%;
  height: 40px;
  border: 1px solid $primary-color;
  border-radius: 4px;
  background-color: transparent;
  margin-right: 10px;
  .btn-label {
    font-family: "Open-Sans";
    font-weight: 600;
    font-size: 14px;
    color: $primary-color;
  }
}
.secondary-btn {
  width: 100%;
  height: 40px;
  border: 1px solid $secondary-color;
  border-radius: 4px;
  background-color: transparent;
  margin-right: 10px;
  .btn-label {
    font-family: "Open-Sans";
    font-weight: 600;
    font-size: 14px;
    color: $secondary-color;
  }
}
.secondary-btn-bg {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: $secondary-color;
  border: 0px;
  margin-right: 10px;
  .btn-label {
    font-family: "Open-Sans";
    font-weight: 600;
    font-size: 14px;
    color: $white;
  }
}

@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";


.cancel-btn {
  width: 100%;
  height: 40px;
  border: 1px solid $primary-color;
  border-radius: 4px;
  background-color: transparent;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  .btn-label {
    font-family: "Open-Sans";
    font-weight: 600;
    font-size: 14px;
    color: $primary-color;
  }
}

.container-modal {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: "100vw";
    height: "100vh";
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
}

.CloseButton {
    border-style: none;
    background-color: white;

    left: 90%;
    position: absolute;
}
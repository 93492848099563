.permission-container{
    margin: auto;
    text-align: center;
}
.no-permission{
    width: 30% !important;
    height: 30vh !important;
    margin: auto;
    margin-top: 5%;
}

.no-permission-text{
    font-family: 'Open-Sans';
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
}
@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";


.checkbox-input {
  width: 24px;
  height: 24px;
  border: 1px solid $primary-color;
  border-radius: 4px;
  cursor: pointer;
  &:checked {
    background-color: $white;
    border-color: $primary-color;
  }
}
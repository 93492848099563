@import "../../theme/fonts.scss";
@import "../../theme/variables.scss";

.dashboard-card {
  width: 95%;
  height: 106px;
  border: 1px solid #dedede;
  border-radius: 4px;
  display: flex;
  margin-right: 1%;
  font-family: "Open-Sans";

  color: $primary-color;
  .loss {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $red;
    height: 100%;
    width: 5%;
  }
  .neutral {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $yellow;
    height: 100%;
    width: 5%;
  }
  .profit {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $green;
    height: 100%;
    width: 5%;
  }
  .dash-card-title {
    font-size: 14px;
    font-weight: 400;
    font-family: "Open-Sans";
  }
  .dash-card-content {
    font-size: 24px;
    font-weight: 600;
    font-family: "Open-Sans";
  }
  .dashboard-card-footer-container {
    display: flex;
    align-items: center;
    height: 10%;
    // background-color: aqua;

    .card-footer-content {
      margin: 0 10px;
      font-size: 14px;
      font-family: "Open-Sans";
    }
  }
  .footer-line-image {
    width: 22px;
    height: 2px;
  }
  .footer-trend-image {
    width: 20px;
    height: 10px;
  }
}

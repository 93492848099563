@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.free-feature-user-container {
  padding: 10px 25px;
}
.free-feature-container {
  border-top: 1px solid $border-color;
}
.free-feature {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid $border-color;
}

.free-feature-icon {
  width: 28px;
  height: 28px;
}

.free-feature-down-icon {
  width: 16px;
  height: 16px;
}
.free-feature-title {
  font-family: "Open-Sans";
  font-weight: 400;
  font-size: 14px;
  color: $primary-color;
}
.free-feature-input {
  width: 46px;
  height: 40px;
  border: 1px solid #a4a4a4;
  text-align: center;
  border-radius: 4px;
  outline: none;
  border-color: initial;
  color: $primary-color;
  margin-left: 20px;
  //   margin: 25px 20px;
}
.free-feature-label {
  font-family: "Open-Sans";
  font-weight: 400;
  font-size: 14px;
  color: $primary-color;
  padding-left: 5px;
}

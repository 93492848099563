@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.or-text-terms {
  font-size: 14px;
  font-family: "Open-Sans";
  font-weight: 400;
  color: $primary-color;
  margin: 0 5px;
}
.terms-left-line {
  width: 49%;
  color: $primary-color;
  opacity: 0.75 !important;
}
.terms-right-line {
  color: $primary-color;
  width: 49%;
  opacity: 0.75 !important;
}
.terms-line-container {
  display: flex;
  align-items: center;
}

/* Custom styles for the toolbar */
.custom-toolbar {
  background-color: #ff0000 !important;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

/* Custom styles for the buttons */
.custom-btn {
  
}

.custom-btn:hover {
  background-color: #f11313;
}

.custom-btn:active {
  transform: translateY(1px);
}

.para-style-body{
  font-family: "Open-Sans" !important;
  line-height: 25px !important;
}
@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.notification-main-container {
  height: 72px;
  width: 100%;
  border: 1px solid $border-color;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  margin-bottom: 15px;
}
.notification-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary-color;
  border-radius: 4px;
  margin-right: 20px;
}
.notification-icon {
  width: 18px;
  height: 18px;
}
.notification-title {
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 18px;
  color: $primary-color;
}
.notification-content {
  font-family: "Open-Sans";
  font-weight: 400;
  font-size: 14px;
  color: $primary-color;
}

@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.chart-card-main-container {
  width: 98%;
  min-height: 364px;
  border: 1px solid $border-color;
  margin-right: 1%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.chart-card-container {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;

  .pie-chart-card {
    width: 219px;
    height: 219px;
    margin-top: 40px;
  }

  .chart-card {
    width: 533px;
    height: 252px;
    margin-top: 40px;
  }
}

.chart-title-container {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding: 0 10px;
  // border-radius: 4px 4px 0 0;

  .chart-title {
    font-size: 18px;
    font-weight: 600;
    font-family: "Open-Sans";
    color: $secondary-color;
  }
}

.footer-main-container {
  width: 98%;
  height: 454px;
  border: 1px solid $border-color;
  margin-right: 1%;
  margin-top: 5px;
  margin-bottom: 10px;

  .chart-card-container {
    width: 100%;
    height: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-card-container {
    display: flex;
    justify-content: space-around;
    height: 68px;

    .footer-card-data {
      width: 181px;
      height: 68px;
      border: 1px solid #dedede;
      border-radius: 4px;
      padding: 5px 15px;

      .footer-card-title {
        font-family: "Open-Sans";
        font-weight: 400;
        font-size: 14px;
        color: $primary-color;
      }

      .footer-card-content {
        font-family: "Open-Sans";
        font-weight: 600;
        font-size: 24px;
        color: $primary-color;
      }
    }
  }
}


.no-chart-data {
  color: $primary-color;
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
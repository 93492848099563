@import "../../theme/fonts.scss";
@import "../../theme/variables.scss";

.select-badge {
  font-size: 18px;
  color: $secondary-color;
  font-weight: 600;
  font-family: "Open-Sans";
}

.bg-red {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $red;
}

.bg-green {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $green;
}

.bg-blue {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: blue;
}

.bg-white {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid black;
}

.bg-black {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
}

.create-badge-container {
  width: 250px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
  margin-top: 10px;
}

.badge-text {
  font-size: 20px;
  font-family: 'Open-Sans';
  font-weight: 700;
}

.badge-body-text {
  font-size: 16px;
  font-family: 'Open-Sans';
  color: #351C75;
  margin-top: 10px !important;
  max-width: 500px;
  overflow: hidden;
  height: fit-content;
}

.badge-image-container {
  width: 70px;
  height: 70px;
  border-radius: 100px;
}

.badge-container {
  margin-top: 15px;
}

.badge-body-container {
  width: 500px; /* Set your desired width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-body-text {
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
}

.badge-body-text-description {
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  font-family: 'Open-Sans';
  margin-top: 10px;
}

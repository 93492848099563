@import "../../theme/fonts.scss";
@import "../../theme/variables.scss";

.customer-care-search-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-end;
}
.customer-care-search-input {
  width: 327px;
  height: 40px;
}
.customer-care-edit-icon {
  width: 24px;
  height: 24px;
}

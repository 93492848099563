@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.table-container {
  overflow: hidden;
  min-width: 100%;
}

.table-scroll {
  overflow-x: auto;
}

.main-table {
  border-radius: 4px 4px 0 0;
  // overflow: hidden;
  min-width: 100%;
  border-collapse: separate;
  // height: 100%;
  font-family: "Open-Sans";

  .subscription-table-header {
    background-color: $primary-color;
    color: $white;

    th {
      padding: 12px 10px;
      text-align: start;
      font-size: 14px;
      font-weight: 400;
      color: $white;
      font-family: "Open-Sans";
    }
  }

  .normal-table-header {
    background-color: $secondary-color;
    color: white;
    white-space: nowrap;

    th {
      padding: 12px 10px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: $white;
      font-family: "Open-Sans";
      background-color: $secondary-color;
    }
  }
}

tbody {
  tr {
    background-color: white;
  }

  td {
    padding: 12px 10px;
    border-bottom: 1px solid rgb(219, 215, 215);
    color: $primary-color;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-family: "Open-Sans";
    min-width: 100px;
    background-color: $white;
  }
}

.notification-table-icon {
  width: 14px;
  height: 14px;
  margin-bottom: 3px;
  margin-left: 5px;
}

.show-text {
  font-family: "Open-Sans";
  font-weight: 400;
  font-size: 14px;
  color: $primary-color;
}

.table-filter-icon {
  width: 13px;
  height: 13px;
  margin-left: 5px;
  cursor: pointer;
}

.filter-popup-main-container {
  padding: 20px;
  height: 85%;
}

.filter-popup-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-users {
  font-family: "Open-Sans";
  font-weight: 600;
  color: $secondary-color;
  font-size: 18px;
  // line-height: 21.79px;
}

.filter-date-input {
  display: flex;
  width: 100%;
}

.filter-from-input {
  width: 47%;
  margin-right: 2%;
}

.filter-to-input {
  width: 47%;
}

.filter-popup-btn-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $border-color;
}

.filter-cancel-btn-container {
  width: 70px;
  margin-right: 10px;
  height: 40px;
}

.filter-save-btn-container {
  width: 86px;
  height: 40px;
  margin-right: 20px;
}

.clear-filter {
  font-family: "Open-Sans";
  font-weight: 700;
  font-size: 14px;
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
}

.filter-popup {
  width: 100%;
  // height: 500px;
}
.users-pagination {
  margin-top: 5px;
  max-width: 100%;
  overflow-x: auto;
}

.table-header-custom{
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-applied{
  width: 5px;
  height: 5px;
  background-color: rgb(255, 0, 0);
  border-radius: 50px;
  position: absolute;
  margin-left: 16px;
    margin-top: 5px;
}
@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.text-area-input {
  width: 100%;
  height: 114px;
  border: 1px solid $primary-color;
  border-radius: 4px;
  outline: none;
  border-color: initial;
  resize: none;
  padding: 10px 15px;
  color: $primary-color;
  font-size: 14px;
  font-family: "Open-Sans";

  &::placeholder {
    font-family: "Open-Sans";
    font-weight: 400;
    font-size: 14px;
  }
}

.terms-text-area {
  width: 100%;
  height: 152px;
  border: 1px solid $primary-color;
  border-radius: 4px;
  outline: none;
  border-color: initial;
  resize: none;
  padding: 10px 15px;
  color: $primary-color;
  font-size: 14px;
  font-family: "Open-Sans";

  &::placeholder {
    font-family: "Open-Sans";
    font-weight: 400;
    font-size: 14px;
  }
}
.text-error {
  border: 1px solid red;
}


.text-disabled{
  cursor: not-allowed;
}
@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.edit-ticket-status {
  font-size: 18px;
  color: $secondary-color;
  font-family: "Open-Sans";
  font-weight: 600;
  margin-top: 20px;
}

.previous-ticket-container {
  width: 100%;
  height: 40px;
  border: 1px solid $primary-color;
  border-radius: 4px;
  outline: none;
  border-color: initial;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.ticket-background {
  cursor: pointer;
  background-color: $border-color;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;

  span {
    font-family: 'Open-Sans';
    color: $primary-color;
    font-weight: 700;
  }
}

.no-previous-tickets {
  font-family: 'Open-Sans';
  color: $primary-color;
  font-size: 10px;
}
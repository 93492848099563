@import "../../theme/fonts.scss";
@import "../../theme/variables.scss";

.users-main-container {
  padding: 10px 25px;
}
.users-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.users-title {
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 24px;
  color: $secondary-color;
}
.users-input-container {
  width: 80%;
  margin-bottom: 40px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //   justify-content: space-around;
}
.search-container {
  width: 327px;
  height: 40px;
  margin-right: 25px;
}
.subscription-container {
  width: 153px;
  height: 40px;
}
.subs-container-new{
  width: 250px;
  height: 40px;
}
.user-filter-container {
  width: 77px;
  height: 40px;
}
.subscription-label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open-Sans";
  color: $primary-color;
}
.action-icon {
  width: 20px;
  height: 4px;
}

.delete-popup-container {
  // padding: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup-close-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.delete-users {
  font-family: "Open-Sans";
  font-weight: 600;
  color: $secondary-color;
  font-size: 18px;
  line-height: 21.79px;
}
.confirm-delete-container {
  border-bottom: 1px solid $border-color;
}
.confirm-delete {
  font-family: "Open-Sans";
  font-weight: 400;
  font-size: 14px;
  color: $secondary-color;
  margin: 0 20px 20px 20px;
}
.delete-popup-btn-container {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
}
.cancel-btn-container {
  width: 104px;
  margin-right: 10px;
  height: 40px;
}
.delete-btn-container {
  width: 86px;
  height: 40px;
}
.promo-btn-container {
  width: 300px;
  height: 40px;
  margin-right: 10px;
}

.action-icon-container {
  // position: relative;
}
.delete-popup-modal {
  position: absolute;

  top: 100%;
  right: 0;
  width: 101px;
  height: 116px;
}
.users-table-container {
  min-width: 100%;
  overflow-x: hidden;
}
.username-column {
  // position: relative;
  position: sticky;
  left: 196px;
  top: 0;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5px;
    background-color: rgb(219, 215, 215);
  }
}
.vertical-line-cell {
  // position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 5px;
    background-color: #351c7573;
    border: 1px solid #351c75;
    right: 0;
    top: 0;
  }
}
.checkbox-sticky-container {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5px;
    background-color: rgb(219, 215, 215);
  }
}
.userId-sticky-container {
  position: sticky;
  left: 98px;
  top: 0;
  z-index: 1;
  width: 100%;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5px;
    background-color: rgb(219, 215, 215);
  }
}

.username-column {
  // position: relative;
  position: sticky;
  left: 196px;
  top: 0;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5px;
    background-color: rgb(219, 215, 215);
  }
}


.user-filter-popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
// .user-filter-popup-container {
//   width: 90%;
// }
.filter-left-container {
  padding: 0 20px;
  border-right: 1px solid #dedede;
  height: 100%;
  margin-bottom: 25px;
}
.filter-popup-label {
  font-size: 14px;
  font-family: "Open-Sans";
  color: $primary-color;
  cursor: pointer;
  margin-bottom: 15px;
}
.user-filter-btn-container {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  border-top: 1px solid $border-color;
}
.filter-active-label {
  font-weight: 700;
}
.filter-right-container {
  padding: 0 20px;
  height: 100%;
  margin-bottom: 25px;
}
.manage-btn-container {
  padding: 20px;
  border-top: 1px solid $border-color;
  padding-right: 40px;
}
.filter-center-container {
  border-right: 1px solid $border-color;
  height: 100%;
}
.filter-users-table-container {
  height: 90%;
  overflow-y: auto;
}
.user-filter-main-container {
  height: 400px;
}
.manage-left-container {
  width: 25%;
  border-right: 1px solid $border-color;
  padding: 0 20px;
  margin-bottom: 25px;
}
.manage-right-container {
  width: 35%;
  padding: 0 10px;
  margin-bottom: 25px;
}
.manage-center-container {
  width: 40%;
  // padding: 0 20px;
  border-right: 1px solid $border-color;
  margin-bottom: 25px;
}

@import "../../theme/fonts.scss";
@import "../../theme/variables.scss";

.manage-subscription-container {
  padding: 10px 25px;
}

.manage-input-container {
  width: 40%;
  margin-bottom: 40px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.delete-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.country-container {
  width: 280px;
  height: 40px;
}

.close-popup {
  width: 14px;
  height: 14px;
}

.selected-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  label {
    font-family: 'Open-Sans';
    margin-left: 10px;
    font-weight: 600;
    color: $primary-color;
    font-size: 14px;
    cursor: pointer;
  }
}

.checked-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.checked-delete-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 20px;
}

.underlined-text {
  text-decoration: underline;
  cursor: pointer;
}
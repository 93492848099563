@import "../../theme/variables.scss";

.forgot-container {
  text-align: center;
  .forgot-content-title {
    font-size: 24px;
    font-weight: 600;
    font-family: "Open-Sans";
    color: $secondary-color;
  }
  .forgot-login-text {
    font-weight: 400;
    font-size: 18px;
    font-family: "Open-Sans";
    color: $secondary-color;
  }
}

.otp-input {
  width: 46px;
  height: 40px;
  border: 1px solid #a4a4a4;
  text-align: center;
  border-radius: 4px;
  outline: none;
  border-color: initial;
  color: $primary-color;
  margin: 25px 20px;
}
.resend-otp-text-container {
  margin-left: 50px;
  text-align: start;
  .resend-otp-text {
    font-family: "Open-Sans";
    font-size: 14px;
    text-decoration: underline;
    line-height: 16.34px;
    cursor: pointer;
    font-weight: 700;
    color: $primary-color;
  }
}
.otp-seconds {
  background-color: #dfdfdf;
  padding: 3px;
  border-radius: 4px;
}

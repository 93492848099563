.download-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }


.media-tutorial-icon{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
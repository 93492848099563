@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.edit-user-container {
  padding: 10px 25px;
}
.back-to-user-container {
  display: flex;
  align-items: center;
}
.left-icon {
  width: 7px;
  height: 14px;
  margin-right: 10px;
}
.back-to-user {
  font-family: "Open-Sans";
  font-weight: 700;
  font-size: 14px;
  color: $primary-color;
  cursor: pointer;
  text-decoration: underline;
}
.edit-user-details {
  font-weight: 600;
  font-family: "Open-Sans";
  font-size: 24px;
  color: $secondary-color;
  margin: 10px 0;
}
.edit-user-input {
  display: flex;
  flex-wrap: wrap;
}
.edit-input-containers {
  width: 45%;
  margin-right: 15px;
}
.edit-cancel-btn {
  width: 78px;
  height: 40px;
  margin-right: 10px;
}
.edit-update-btn {
  width: 63px;
  height: 40px;
}

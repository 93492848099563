@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.width75 {
  .modal-content {
    width: 75%;
  }
}

.delete-popup-container {
  // padding: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-close-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.delete-users {
  font-family: "Open-Sans";
  font-weight: 600;
  color: $secondary-color;
  font-size: 18px;
  line-height: 21.79px;
}

.confirm-delete-container {
  border-bottom: 1px solid $border-color;
}

.confirm-delete {
  font-family: "Open-Sans";
  font-weight: 400;
  font-size: 14px;
  color: $secondary-color;
  margin: 0 20px 20px 20px;
}

.delete-popup-btn-container {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
}

.cancel-btn-container {
  width: 104px;
  margin-right: 10px;
  height: 40px;
}

.delete-btn-container {
  width: 86px;
  height: 40px;
}

.delete-popup-modal {
  position: absolute;
  top: 100%;
  right: 0;
  width: 101px;
  height: 116px;
}

.history-table {
  width: 90%;
  border-collapse: collapse;
  align-self: center;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px !important;
}

.history-head{
  background-color: #f2f2f2;
  border: 1px solid  $secondary-color;
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 14px;
  color: $secondary-color;
}

.history-row {
  border: 1px solid  $secondary-color;;
  text-align: center;
  padding: 8px;
  font-family: "Open-Sans";
  font-size: 14px;
  color: $secondary-color;
}
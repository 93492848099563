@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.add-subscription-input-label {
  font-size: 12px;
  font-family: "Open-Sans";
  font-weight: 400;
  color: $primary-color;
}
.add-subscription-left-container {
  border: 1px solid $border-color;
  border-radius: 4px 4px 0 0;
  height: 100%;
}
.add-subscription-right-container {
  border: 1px solid $border-color;
  border-radius: 4px 4px 0 0;
}
.update-pricing {
  font-weight: 600;
  font-size: 18px;
  font-family: "Open-Sans";
  color: $secondary-color;
  margin-top: 25px;
}
.update-pricing-main-container {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
}
.update-pricing-container {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open-Sans";
  color: $primary-color;
}
.update-left-container {
  border: 0.5px solid $border-color;
  padding: 10px 5px;
  display: flex;
  align-items: center;
}
.update-right-container {
  border: 0.5px solid $border-color;
  padding: 10px 5px;
}
.add-subscription-input-container {
  padding: 10px;
}
.update-pricing-main-container {
  margin-bottom: 10px;
}
.add-subscription-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.currency {
  font-family: "Open-Sans";
  font-size: 12px;
  margin-right: 5px;
}
.dropdown-icon {
  width: 16px;
  height: 16px;
}
.add-subscription-icon-form{
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 45px;
}
.add-subscription-icon-delete{
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 20px;
}

.new-plan-feature-form{
  border: 1px solid $border-color;
  border-top: none;
}

.countries-offered{
  margin-top: 20px;
}
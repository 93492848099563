@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.file-upload-container {
  width: 100%;
  height: 77px;
  border: 1px dashed $primary-color;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-file-container {
  width: 100%;
  height: 40px;
  border: 1px dashed $primary-color;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-upload-error {
  border: 1px dashed $red;
}
.select-image-text {
  color: $primary-color;
  text-decoration: underline;
  font-weight: 700;
  font-size: 14px;
  font-family: "Open-Sans";
  cursor: pointer;
  padding-top: 15px;
}
.select-image {
  width: 45px;
  height: 45px;
  clip-path: circle();
}
.art-image {
  width: 48px;
  height: 47px;
}
.tutorial-image {
  width: 33px;
  height: 40px;
}
.header-image {
  width: 96px;
  height: 51px;
}
.select-image-container {
  display: flex;
  justify-content: flex-end;
}

.drop-down-image{
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

.file-image{
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.delete-file{
  font-size: 12px;
  cursor: pointer;
  color: $red;
  text-decoration: underline;
  margin-top: 5px;
  font-family: "Open-Sans";
}
@import "../../theme/fonts.scss";
@import "../../theme/variables.scss";

.change-password {
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  color: $primary-color;
  font-family: "Open-Sans";
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  cursor: pointer;
}
.profile-settings-image {
  width: 250px;
  height: 250px;
  clip-path: circle();
}
.profile-image-container {
  position: relative;
}
.profile-edit {
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.profile-delete{
  position: absolute;
  bottom: -30px;
  right: 10px;
  font-family: "Open-Sans";
  font-size: 14px;
  color: red;
  cursor: pointer;
  text-decoration: underline;
}
@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.content-main-container {
  padding: 10px 25px;
}
.content {
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 24px;
  color: $secondary-color;
}
.content-self-care-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.self-care {
  font-family: "Open-Sans";
  font-weight: 400;
  padding: 15px;
  font-size: 14px;
  border: 0.5px solid $primary-color;

  color: $primary-color;
}
.manage {
  font-family: "Open-Sans";
  font-weight: 600;
  border: 0.5px solid $primary-color;
  font-size: 14px;
  padding: 15px;
  color: $primary-color;
  border-left: none;
  cursor: pointer;
}

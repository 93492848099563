.lottie-no-data{
    width: 30% !important;
    height: 30% !important;
}

.container-nodata{
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
}

.no-data{
    font-size: 30px;
}
@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.add-dropdown-container {
  padding: 20px;
}
.or-text {
  font-family: "Open-Sans";
  font-weight: 400;
  font-size: 14px;
  color: $primary-color;
  margin-top: 45px;
  padding-left: 10px;
}

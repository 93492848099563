.lottie-badge {
  width: 250px;
  height: 150px;
}

.lottie-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drop-down-lottie{
  width: 80px;
  height: 80px;
}
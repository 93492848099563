@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.tooletip_lable {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}


.ant-tooltip-inner.ant-tooltip-inner {
    background-color: white !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;
}


.ant-tooltip-arrow::before {
    background-color: white !important;
}


.edit-icon {
    width: 15px;
    height: 15px;
}

.tooletip_icon {
    color: $primary-color;
    margin-left: 12px;
}

.icon-container{
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.edit-icon-container{
    padding-top: 8px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.horizontal-line{
    height: 0.1px;
    width: 100%;
    background-color: $primary-color;
}
.tooltip-container{
    z-index: 999999;
}
.tutorial-search-container {
  width: 327px;
  height: 40px;
}
.tutorial-icon {
  width: 33px;
  height: 33px;
}

.media-tutorial-icon{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
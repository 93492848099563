@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.premium-feature-left-container {
  border: 1px solid $border-color;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  height: 100%;
}
.premium-feature-right-container {
  border: 1px solid $border-color;
  border-radius: 4px 4px 0 0;
  //   border-bottom: none;
  height: 100%;
}
.premium-title-container {
  border-bottom: 1px solid $border-color;
  padding: 5px 10px;
}
.premium-feature-main-title {
  font-family: "Open-Sans";
  font-weight: 600;
  font-size: 18px;
  color: $secondary-color;
}
.premium-feature {
  display: flex;
  align-items: center;
  min-height: 56px;
  border-bottom: 1px solid $border-color;
}
.premium-input-container {
  padding: 10px;
}

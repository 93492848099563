@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.permission-input-label {
  font-weight: 400;
  font-family: "Open-Sans";
  font-size: 14px;
  color: $primary-color;
}

.mt-3{
  margin-top: 40px !important;
}

.checkbox-container{
  width: 15px !important;
}
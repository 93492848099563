@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";
.select-container {
  position: relative;
}
.country-icon-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.error-icon-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.country-input-icon {
  width: 9px;
  height: 13px;
}
.search-select-icon {
  width: 12px;
  height: 12px;
}
.select-item {
  width: 100%;
  height: 40px;
  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: transparent;
  color: $primary-color;
  font-size: 14px;
  padding: 0 20px;
  font-family: "Open-Sans";
}
.country-select {
  width: 100%;
  height: 40px;
  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: transparent;
  color: $primary-color;
  font-size: 14px;
  font-family: "Open-Sans";
  padding: 0 30px;
}
.border-green {
  border: 1px solid $primary-color;
  background-color: $white;
}


.option-dropdown{
  display: flex;
  justify-content: space-between;
}

.icon-dropdown{
  margin-right: 10px;
}

.color-icon{
  width: 20px;
  height: 20px;
  border: 1px solid $primary-color;
  margin-left: 10px;
  border-radius: 50px;
}

.label-value-container{
  display: flex;
  align-items: center;
}

.dropdown-image-item{
  width: 20px;
  height: 20px;
}

.lottie-container-drop-down{
  width: 80%;
  height: 80px;
}
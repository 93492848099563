@import "../../theme/variables.scss";
@import "../../theme/fonts.scss";

.input-field-container {
  position: relative;
  width: 100% !important;

  .input-field {
    width: 100%;
    height: 40px;
    border: 1px solid $primary-color;
    border-radius: 4px;
    outline: none;
    border-color: initial;
    padding: 0 30px;
    color: $primary-color;
    font-size: 14px;
    font-family: "Open-Sans";

    &::placeholder {
      font-family: "Open-Sans";
      font-weight: 400;
      font-size: 12px;
    }
  }
  .normal-input {
    width: 100%;
    height: 40px;
    border: 1px solid $primary-color;
    border-radius: 4px;
    outline: none;
    border-color: initial;
    padding: 0 15px;
    color: $primary-color;
    font-size: 14px;
    font-family: "Open-Sans";

    &::placeholder {
      font-family: "Open-Sans";
      font-weight: 400;
      font-size: 12px;
    }
  }
  .left-icon-container {
    position: absolute;
    left: 10px;
    bottom: 10px;
    .left-input-icon {
      width: 15.5px;
      height: 13px;
    }
    .search-icon {
      width: 12.83px;
      height: 13.83px;
    }
    .password-icon {
      width: 14px;
      height: 15px;
    }
    .username-icon {
      width: 13.36px;
      height: 12.5px;
    }
  }
  .right-icon-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
    .right-input-icon {
      width: 20px;
      height: 20px;
    }
    .eye-input-icon {
      width: 17.5px;
      height: 13.5px;
      cursor: pointer;
    }
    .hide-input-icon {
      width: 17.5px;
      height: 17.5px;
      cursor: pointer;
    }
  }
  .reset-right-icon-container {
    position: absolute;
    right: 40px;
    bottom: 10px;
    .right-input-icon {
      width: 20px;
      height: 20px;
    }
    .eye-input-icon {
      width: 17.5px;
      height: 13.5px;
      cursor: pointer;
    }
  }

  .checkbox-input {
    width: 24px;
    height: 24px;
    border: 1px solid $primary-color;
    border-radius: 4px;
    // appearance: none;
    cursor: pointer;
    &:checked {
      background-color: $white;
      border-color: $primary-color;

      // &::before {
      //   content: "\2713";
      //   display: block;
      //   text-align: center;
      //   line-height: 24px;
      //   font-size: 20px;
      //   margin: inherit;
      //   font-weight: 700;
      //   color: $primary-color;
      // }
    }
  }
  .checkbox-white-input {
    width: 24px;
    height: 24px;
    border: 1px solid $white;
    border-radius: 4px;
    // appearance: none;
    cursor: pointer;
    &:checked {
      background-color: $white;
      border-color: $primary-color;

      // &::before {
      //   content: "\2713";
      //   display: block;
      //   text-align: center;
      //   line-height: 24px;
      //   font-size: 20px;
      //   font-weight: 700;
      //   color: $primary-color;
      // }
    }
  }
  .input-error {
    border: 1px solid red;
  }
  .border-green {
    border: 1px solid $primary-color;
  }
  .text-area-input {
    width: 100%;
    height: 114px;
    border: 1px solid $primary-color;
    border-radius: 4px;
    outline: none;
    border-color: initial;
    // padding: 0 30px;
    color: $primary-color;
    font-size: 14px;
    font-family: "Open-Sans";

    &::placeholder {
      font-family: "Open-Sans";
      font-weight: 400;
      font-size: 12px;
    }
  }
}

.cursor-none{
  cursor: not-allowed;
}


.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 3px;
  position: relative;
  outline: none;
  cursor: pointer;
}

/* Checked state */
.custom-checkbox:checked {
  background-color: transparent; /* Change background color when checked */
  border-color: $primary-color;; /* Change border color when checked */
}

/* Style the tick mark */
.custom-checkbox::before {
  content: '\2713'; /* Unicode character for checkmark */
  font-size: 16px;
  color: $primary-color;; /* Color of the tick mark */
  position: absolute;
  top: -4px;
  left: 3px;
  display: none; /* Hide the tick mark by default */
}

/* Show the tick mark when the checkbox is checked */
.custom-checkbox:checked::before {
  display: block;
}
#main {
  transition: margin-left 0.5s;
  font-family: "Inter";
  margin-left: 15% !important;
  margin-top: 75px !important;
  // background: linear-gradient(336deg, #faa21c 0%, #383333 30%);
  background: #ffffff;
}

.footer-item {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
}

.login-main-container {
  height: 100vh;
  width: 100%;
  display: flex;
}

.login-background-container {
  background-image: url("../assets/images/loginBg.png");
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .login-image {
    height: 95%;
  }
}
